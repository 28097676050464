.rvsp {
  margin: 3px 3px 3px 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-grow: 4;
  font-size: 2rem;
}
.rvsp img {
  width: 25%;
  margin: -5%;
}
@media only screen and (max-width: 768px) { 
  .rvsp img { 
    width: 51.2%;
    margin: -13.09%; 
  } 
}
@media only screen and (min-width: 767px) {
  .rvsp img 
  { width: 25.6%; 
    margin: -7.7%;
  }
}
@media only screen and (min-width: 992px) {
  .rvsp img 
  { width: 33.1%; 
    margin: -9.9%;
  }
}
@media only screen and (min-width: 1200px) {
  .rvsp img {
    width: 40.0%; 
    margin: -12.0%;
  }
}

.rvsp {
    font-size: 16px;
  
}

.rvsp .second-image img {
  rotate: 90;
}

.rvsp h1 {
  margin-bottom: 0;
}



:focus{outline: none;}

/* css form effects must have a relative parent */
.col-3{
  width: 100%; 
  position: relative;
} 

/* the effect */

.effect-18{
  border: 0; 
  padding: 4px 0; 
  text-align: center;
  background-color: transparent;
}

.effect-18 ~ .focus-border{
  position: absolute; 
  bottom: 0; 
  left: 0; 
  width: 100%; 
  height: 2px; 
  z-index: 99;
}

.effect-18 ~ .focus-border:before, 
.effect-18 ~ .focus-border:after{
  content: "";
  border-bottom: 1px solid black;
  color: black;
  position: absolute; 
  bottom: 0; 
  left: 0; 
  width: 0; 
  height: 100%; 
  transition: 1s;
}
.effect-18 ~ .focus-border:after{
  left: auto; 
  right: 0;
}

.effect-18:focus ~ .focus-border:before, 
.effect-18:focus ~ .focus-border:after,
.has-content.effect-18 ~ .focus-border:before,
.has-content.effect-18 ~ .focus-border:after{
  width: 50%; 
}



.effect-18 ~ label{
  position: absolute;
  left: 0; 
  width: 100%; 
  top: 6px; 
  color: rgb(100, 100, 100);
  transition: 0.3s; 
  pointer-events: none;
  letter-spacing: 0.5px;
}
  
.effect-18:focus ~ label, .has-content.effect-18 ~ label{
  top: -16px;
  color: black;
  font-size: 12px; 
}
.effect-18[value]:not([value=""]) ~ label{
  top: -16px;
  color: rgb(235, 232, 232);
  font-size: 12px; 
}

.effect-18[value]:not([value=""]) ~ .focus-border{
  border-bottom: 1px solid black;
}


.dietary-preference {
  display: flex;
  justify-content: center;
}
