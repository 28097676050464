nav {
    background-color: #f9f9f9;
    width: 100%;
    height: 20%;
    top: 0;
    z-index: 2;
    box-shadow: 0px 1px 2px #888;
}


nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

nav li {
    flex: 1;
    text-align: center;
}

nav a {
    color: #333;
    text-decoration: none;
    padding: 10px;
    display: block;
    font-size: 1.2rem;
}

nav a:hover {
    background-color: #7d7d7d;
}

/* Media Queries */

@media screen and (max-width: 600px) {
    nav a {
        font-size: 0.8rem;
    }

    .navbar.fixed {
        position: fixed;
        top: 0;
        height: 5%;
    }
}