.forest {
    width: 100%;
    height: auto;
    margin-top: -100px;
}

.heading img {
    width: 55%;
    height: 1%;
    margin-top: -7%;
}

img.forest {
    width: 100%;
}

@media only screen and (max-width: 786px) {
    img.header {
        width: 40%;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    img.forest {
        margin-top: -50px;
    }
}

.title_and {
    font-family: "Snell Roundhand";
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    text-transform: lowercase;
    letter-spacing: normal;
    font-size: 23.8px;
    color: rgb(255, 255, 255);
}