:root {
  --font-color: black;
  --button-color: white;
  --background-font: #c09b55;
  --button-hover-bg: rgb(33, 33, 33);
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #263C50;
  color: #333;
  text-align: center;
  }

h1, h2, h3 {
  font-family: "MrsEavesAllPetiteCaps Regular";
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  margin-top: 10px;
}

h4, h5, p, a {
  font-family: "MrsEavesRoman Regular";
  font-size: 14px;
  line-height: 1.5;
  color: rgb(255, 255, 255);
  text-align: center;
}

hr.hr-tails {
  background: #3A723A;
  border: 0;
  display: block;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  margin: rem 0;
}



button {
  background-color: white;
  font-size: 16px;
  color: black;
  border: 1px solid black;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  border-color: black;
  margin-right: 1px;
}

button:hover {
  background-color: #3A723A;
  color: white;
}

form {
  width: 80%;
  margin: 50px auto;
  text-align: center;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);

}

@media only screen and (max-width: 767px) {
  form {
    width: 70%;
  }
} 

@media only screen and (min-width: 768px) {
  form {
    width: 50%;
    
  }
} 

@media only screen and (min-width: 992px) {
  form {
      width: 40%;
  }
}

@media only screen and (min-width: 1200px) {
  form {
    width: 32%;
  }
} 

@media only screen and (min-width: 1850px) {
  form {
    width: 20%
  };
} 



form p {
  color: black;
}

form h1,
form h2,
form h3,
form h4,
form p {
  color: black;
}


form input[type=text] {
  background-color: transparent;
  color: black;
}

form input[type=submit] {
  background-color: white;
  font-size: 16px;
  color: black;
  border: 1px solid black;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  border-color: black;
  margin-right: 1px;
}

form input[type=submit]:hover {
  background-color: #3A723A;
  color: white;
}


form input[type=submit].selected {
  background-color: black;
  border-color: white;
  color: white;
}